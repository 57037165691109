import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import User from './users';
import Notification from './notifications';

const appReducer = combineReducers({
  form: formReducer,
  User,
  Notification
});

const rootReducer = (state, action) => {
  return appReducer(state, action)
}

export default rootReducer;