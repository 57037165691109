import axios from 'axios';
import { API_ROOT } from '../constants';
import { _dispatch } from '../utilities';
export {_dispatch};
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const REFRESH_NOTIFICATION = 'REFRESH_NOTIFICATION';
export const UPDATE_NOTIFICATION_COUNT = 'UPDATE_NOTIFICATION_COUNT';
export const REFRESH_COUNT = 'REFRESH_COUNT';

const ROOT_URL = API_ROOT;

export function updateNofications(payload) {
  console.log('payload', payload);
  return (dispatch) => { 
    dispatch({
      type: UPDATE_NOTIFICATION,
      payload: payload.data
    });

    dispatch({
      type: UPDATE_NOTIFICATION_COUNT
    });
  };
}

export function fetchNotifications(payload) {
  return (dispatch) => { 
    return axios({
        method: 'GET',
        params: payload,
        url: `${API_ROOT}/admin/list-notifications`
    }).then(response => {
      dispatch({
        type: REFRESH_NOTIFICATION,
        payload: response.data.body
      });
    });
  };
}

export function fetchNotificationCount(payload) {
  return (dispatch) => { 
    return axios({
        method: 'GET',
        url: `${API_ROOT}/admin/notification-count`
    }).then(response => {
      dispatch({
        type: REFRESH_COUNT,
        payload: response.data.body.count
      });
    });
  };
}

export function readNotification(notificationId) {
  return axios({
      method: 'POST',
      data: {notificationId},
      url: `${API_ROOT}/admin/mark-notification-as-read`
  });
}