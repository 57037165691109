import * as firebase from "firebase/app";
import "firebase/messaging";
import {firebaseConfig} from './constants';

export const initializeFirebase = () => {
  firebase.initializeApp(firebaseConfig);
}

/*
 This is to get browser permission to show notification
*/
export const askForPermissioToReceiveNotifications = async () => {
  try {
    const messaging = firebase.messaging();
    await messaging.requestPermission();
    const token = await messaging.getToken();
    console.log('token', token);
    return token;
  } catch (error) {
    console.error(error);
  }
}

/*
    This is the function that gets triggered when you receive a 
    push notification while you’re on the page. So you can 
    create a corresponding UI for you to have the push 
    notification handled.
*/
export function onMessage(callback) {
   const messaging = firebase.messaging();
   messaging.onMessage(callback);
}