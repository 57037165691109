import {
  _dispatch,
  UPDATE_NOTIFICATION,
  REFRESH_NOTIFICATION,
  UPDATE_NOTIFICATION_COUNT,
  REFRESH_COUNT
} from '../actions/notifications';


const INITIAL_STATE = {notifications: [], count: 0};

export default function(state = INITIAL_STATE, action) {

  switch(action.type) {

  	case REFRESH_NOTIFICATION:
  		return _dispatch({...state, notifications: action.payload}, true, 'notifications');

  	case UPDATE_NOTIFICATION:
  		return _dispatch({...state, notifications: [action.payload, ...state.notifications]}, true, 'notifications');

  	case REFRESH_COUNT:
  		return _dispatch({...state, count: action.payload}, true, 'notifications-count');

  	case UPDATE_NOTIFICATION_COUNT:
  		return _dispatch({...state, count: (state.count + 1)}, true, 'notifications-count');
    
    default:
      return state;
  }
}


