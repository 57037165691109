import React, { Component, Suspense } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import {ROUTES} from './routes';
import ContentLoader from './components/ContentLoader';
import RenderRemoteComponent from './RenderRemoteComponent';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';
import "bootstrap-icons/font/bootstrap-icons.css";

class App extends Component {

  render() {
    return (
        <>
          <BrowserRouter>
            <Suspense fallback={<ContentLoader />}>
              <Switch>
                <Route {...ROUTES.ROOT} />
                <Route {...ROUTES.RESETPASSWORD} />
                <Route {...ROUTES.ADMIN} />
                <Route {...ROUTES.LOGIN} />
                <Route {...ROUTES.LOGOUT} />
                <Route {...ROUTES.NOTFOUND} />
              </Switch>
              <RenderRemoteComponent />
            </Suspense>
           
          </BrowserRouter>
         
           <ToastContainer />
           <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_CENTER}/> 
        </>
    );
  }
}

export default App;
